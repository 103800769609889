
export default {
    isNumbar(value) {
        if (Object.prototype.toString.call(value) === "[object Number]") {
            return true;
        }
        return false;
    },
    isDate(value) {
        if (Object.prototype.toString.call(value) === "[object Date]") {
            return true;
        }
        return false;
    },
    isTime(value) {
        if (Object.prototype.toString.call(value) === "[object Object]") {
            if (value.hour !== undefined && value.minute !== undefined) {
                return true;
            }
        }
        return false;
    },
    isNull(value) {
        if (value === undefined || value === null) {
            return true;
        }
        return false;
    },
    nullToDefault(value, defaultValue) {
        if (this.isNull(value)) {
            return defaultValue;
        }
        return value;
    },
}
