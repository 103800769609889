<template>
  <v-btn
    @click="clickEvent"
    :max-width="maxWidth"
    :min-width="minWidth"
    :width="width"
    :disabled="disabled"
    :small="small"
    :x-small="xSmall"
    :large="large"
    :x-large="xLarge"
    text
    class="icon-label-btn"
  >
    <div v-if="textPosition === 'bottom'">
      <div class="d-flex align-center justify-center">
        <v-icon :color="_color">{{ _mdiIcon }}</v-icon>
      </div>
      <div class="icon-btn-text icon-btn-text-bottom">{{ btnText }}</div>
    </div>
    <div v-else-if="textPosition === 'left'">
      <div class="d-flex align-center justify-center">
        <div class="icon-btn-text mr-1">{{ btnText }}</div>
        <v-icon :color="_color">{{ _mdiIcon }}</v-icon>
      </div>
    </div>
    <div v-else class="d-flex align-center justify-center">
      <v-icon :color="_color" class="mr-1">{{ _mdiIcon }}</v-icon>
      <div class="icon-btn-text">{{ btnText }}</div>
    </div>
  </v-btn>
</template>

<script>
  export default {
    props: {
      mdiIcon: {
        type: String,
        default: undefined,
      },
      btnText: {
        type: String,
        default: undefined,
      },
      width: {
        type: [String, Number],
        default: null,
      },
      maxWidth: {
        type: [String, Number],
        default: null,
      },
      minWidth: {
        type: [String, Number],
        default: null,
      },
      type: {
        type: String,
        default: "none",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      xSmall: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      xLarge: {
        type: Boolean,
        default: false,
      },
      textPosition: {
        type: String,
        default: "Right",
      },
    },
    computed: {
      _mdiIcon: {
        get() {
          let mdiIcon = undefined;
          switch (this.type) {
            case "add":
              mdiIcon = "mdi-plus-circle";
              break;
            case "edit":
              mdiIcon = "mdi-pencil";
              break;
            case "delete":
            case "deleteRed":
              mdiIcon = "mdi-delete";
              break;
            case "role":
              mdiIcon = "mdi-account-check";
              break;
            case "account":
              mdiIcon = "mdi-account-circle";
              break;
            case "fixedCancel":
            case "approveCancel":
              mdiIcon = "mdi-file-cancel-outline";
              break;
            case "accountDetail":
              mdiIcon = "mdi-card-account-details";
              break;
            case "attendanceList":
              mdiIcon = "mdi-table-account";
              break;
            case "overtimeList":
              mdiIcon = "mdi-table-account";
              break;
            case "approveList":
              mdiIcon = "mdi-table-edit";
              break;
            case "recalc":
              mdiIcon = "mdi-calculator";
              break;
            case "calculate":
              mdiIcon = "mdi-calculator-variant";
              break;
            case "logoutText":
              mdiIcon = "mdi-logout";
              break;
            case "approveRequest":
              mdiIcon = "mdi-file-check-outline";
              break;
            case "approveRequestCancel":
              mdiIcon = "mdi-file-document-edit-outline";
              break;
            case "home":
              mdiIcon = "mdi-home";
              break;
            case "print":
              mdiIcon = "mdi-printer";
              break;
            case "changeCostSite":
              mdiIcon = "mdi-map-plus";
              break;
            case "clearCostSite":
              mdiIcon = "mdi-map-minus";
              break;
            case "save":
              mdiIcon = "mdi-content-save-edit";
              break;
            case "close":
              mdiIcon = "mdi-close";
              break;
            case "alert":
              mdiIcon = "mdi-alert";
              break;
            default:
              mdiIcon = this.mdiIcon;
              break;
          }
          return mdiIcon;
        },
      },
      _color: {
        get() {
          let color = undefined;
          switch (this.type) {
            case "add":
            case "delete":
            case "role":
            case "account":
            case "accountDetail":
            case "approveList":
            case "attendanceList":
            case "overtimeList":
            case "approveRequest":
            case "home":
            case "print":
            case "logoutText":
            case "changeCostSite":
            case "clearCostSite":
              color = "#27537B";
              break;
            case "approveCancel":
            case "fixedCancel":
            case "approveRequestCancel":
            case "deleteRed":
            case "close":
              color = "error";
              break;
            case "recalc":
            case "calculate":
            case "save":
              color = "orange darken-2";
              break;
            case "edit":
              color = "green";
              break;
            case "alert":
              color = "grey darken-2"
              break;
            default:
              break;
          }
          return color;
        },
      },
    },
    methods: {
      // ダイアログ閉じる
      clickEvent() {
        this.$emit("btnClick");
      },
    },
  };
</script>

<style scoped lang="scss">
  .icon-label-btn {
    &.v-btn.v-btn--disabled .v-icon {
      color: rgba(0, 0, 0, 0.13) !important;
    }

    &.v-btn:hover::before {
        opacity: 0.18;
    }

    .icon-btn-text {
      color: #54687a #284866;
    }
    .icon-btn-text-bottom {
      font-size: 0.7rem;
    }

    &.v-btn.v-size--large {
      .icon-btn-text {
        color: #54687a #284866;
        font-size: 1.1rem;
      }
    }
  }
</style>
