<template>
  <div
    class="format-label"
    :class="[
      { 'format-label-word-break': wordBreak },
      { 'format-label-pre-wrap': preWrap },
      { tablet: _isTablet },
      { 'format-label-fulid': !noFluid },
      { minus: _isMinus },
    ]"
    v-text="_displayValue"
  />
</template>

<script>
  import { FORMAT_LABEL_FORMAT } from "@/common/const.js";
  import { FORMAT_DATE_STRING } from "@/common/date.js";
  import { default as dateHelper } from "@/common/date.js";
  import { default as typeHelper } from "@/common/type.js";

  export default {
    props: {
      value: {
        type: [String, Number, Date, Object],
        default: null,
      },
      format: {
        type: Number,
        default: FORMAT_LABEL_FORMAT.TYPE.STRING,
      },
      wordBreak: {
        type: Boolean,
        default: false,
      },
      preWrap: {
        type: Boolean,
        default: false,
      },
      noFluid: {
        type: Boolean,
        default: false,
      },
      tablet: {
        type: Boolean,
        default: false,
      },
      appendString: {
        type: String,
        default: null,
      },
      afterAppendString: {
        type: String,
        default: null,
      },
      numberNull: {
        type: Boolean,
        default: false,
      },
      minus: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _displayValue: {
        get() {
          let dispValue = this.value;
          switch (this.format) {
            case FORMAT_LABEL_FORMAT.TYPE.YYYYMMDD:
              dispValue = dateHelper.formatString(
                this.value,
                FORMAT_DATE_STRING.TYPE.YYYYMMDD
              );
              break;
            case FORMAT_LABEL_FORMAT.TYPE.YYYY_MM_DD:
              dispValue = dateHelper.formatString(
                this.value,
                FORMAT_DATE_STRING.TYPE.YYYY_MM_DD
              );
              break;
            case FORMAT_LABEL_FORMAT.TYPE.YYYY_MM_DD_JP:
              dispValue = dateHelper.formatString(
                this.value,
                FORMAT_DATE_STRING.TYPE.YYYY_MM_DD_JP
              );
              break;
            case FORMAT_LABEL_FORMAT.TYPE.YYYY_MM_DD_WEEK:
              dispValue = dateHelper.formatString(
                this.value,
                FORMAT_DATE_STRING.TYPE.YYYY_MM_DD_WEEK
              );
              break;
            case FORMAT_LABEL_FORMAT.TYPE.YYYY_MM_JP:
              dispValue = dateHelper.formatString(
                this.value,
                FORMAT_DATE_STRING.TYPE.YYYY_MM_JP
              );
              break;
            case FORMAT_LABEL_FORMAT.TYPE.MM_DD_SLA:
              dispValue = dateHelper.formatString(
                this.value,
                FORMAT_DATE_STRING.TYPE.MM_DD_SLA
              );
              break;
            case FORMAT_LABEL_FORMAT.TYPE.MM_DD_WEEK_SLA:
              dispValue = dateHelper.formatString(
                this.value,
                FORMAT_DATE_STRING.TYPE.MM_DD_WEEK_SLA
              );
              break;
            case FORMAT_LABEL_FORMAT.TYPE.YYYY_MM_DD_HH_MM_SS:
              dispValue = dateHelper.formatString(
                this.value,
                FORMAT_DATE_STRING.TYPE.YYYY_MM_DD_HH_MM_SS
              );
              break;
            case FORMAT_LABEL_FORMAT.TYPE.HHMM:
              if (typeHelper.isTime(this.value)) {
                dispValue =
                  ("00" + this.value.hour).slice(-2) +
                  ("00" + this.value.minute).slice(-2);
              }
              break;
            case FORMAT_LABEL_FORMAT.TYPE.HH_MM:
              if (typeHelper.isTime(this.value)) {
                dispValue =
                  ("00" + this.value.hour).slice(-2) +
                  ":" +
                  ("00" + this.value.minute).slice(-2);
              }
              if (typeHelper.isNumbar(this.value)) {
                let value = this.value;
                const hour = value / 60;
                const minute = value % 60;
                const len = String(Math.floor(hour)).length
                dispValue =
                  ("00" + Math.floor(hour)).slice(-len) +
                  ":" +
                  ("00" + Math.floor(minute)).slice(-2);
              }
              break;
            case FORMAT_LABEL_FORMAT.TYPE.HH_MM_JP:
              if (typeHelper.isTime(this.value)) {
                dispValue =
                  ("00" + this.value.hour).slice(-2) +
                  "時" +
                  ("00" + this.value.minute).slice(-2) +
                  "分";
              }
              break;
            case FORMAT_LABEL_FORMAT.TYPE.NUMBER_COMMNA:
              if (typeHelper.isNumbar(this.value)) {
                dispValue = this.value.toLocaleString();
              }
              break;
            case FORMAT_LABEL_FORMAT.TYPE.NUMBER_COMMNA_THOUSAND:
              if (typeHelper.isNumbar(this.value)) {
                dispValue = this.value * 1000;
                dispValue = dispValue.toLocaleString();
              }
              break;
            default:
              break;
          }
          if (this.appendString !== undefined && this.appendString !== null) {
            dispValue = this.appendString + (dispValue !== null ? dispValue : "");
          }
          if (this.afterAppendString !== undefined && this.afterAppendString !== null) {
            dispValue = (dispValue !== null ? dispValue : "") + this.afterAppendString;
          }
          if (this.numberNull && this.value === 0) {
            dispValue = null;
          }
          return dispValue;
        },
      },
      _isTablet: {
        get() {
          return this.tablet;
        },
      },
      _isMinus: {
        get() {
          if (this.value < 0) {
            return this.minus;
          }
        },
      },
    },
    methods: {},
  };
</script>

<style scoped>
  .format-label-fulid {
    height: 100%;
    width: 100%;
  }

  .format-label-word-break {
    word-break: break-word;
  }

  .format-label-pre-wrap {
    white-space: pre-line;
  }

  .format-label.tablet {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .format-label.minus {
    color: #fc0000;
  }
</style>
