import Vue from 'vue';
import VueAxios from 'vue-axios'
import axios from 'axios'
import { APP } from "@/common/const.js";

Vue.use(VueAxios, axios)

// ベースURL
axios.defaults.baseURL = process.env.VUE_APP_DOCUMENT_ROOT;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// ブラウザバック時のaxiosでcacheを使用しないようにする
axios.defaults.headers.common['Cache-Control'] = 'no-cache';
// axios共通処理の定義
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        let errorMessages = [];
        if (error.response.status === 401) {
            // クライアント認証エラー
            // ログイン画面へ遷移
            let redirectUrl = encodeURIComponent(location.pathname + location.search);

            window.location.href = APP.LOGIN_URL + "?redirect=" + redirectUrl;
        }
        else if (error.response.status === 422) {
            // バリデーションエラー
            _.forEach(error.response.data.errors, (value, key) => {
                errorMessages = _.concat(errorMessages, value);
            });
            error.apiErrorMessages = errorMessages;
        } else if (_.has(error, "response.data.error_description")) {
            // APIエラー
            errorMessages = _.concat(errorMessages, error.response.data.error_description);
            error.apiErrorMessages = errorMessages;
        }

        return Promise.reject(error);
    }
)

// javascriptのdate型からJSONに変換する際の変換文字列定義
// API側のTimezoneがlocal(Asia/Tokyo)を想定しているため、UTCから現地時間に変換して送信する。
Date.prototype.toJSON = function() {
    return this.getFullYear() + '-' + ('0'+(this.getMonth()+1)).slice(-2) + '-' + ('0'+this.getDate()).slice(-2) + 'T' +
     ('0'+this.getHours()).slice(-2) + ':' + ('0'+this.getMinutes()).slice(-2) + ':' + ('0'+this.getSeconds()).slice(-2);
}