<template>
  <section class="print-sheet" :style="styles">
    <slot></slot>
  </section>
</template>

<script>
  export default {
    components: {},
    data() {
      return {};
    },
    props: {
      width: {
        type: String,
        default: "auto",
      },
      height: {
        type: String,
        default: "auto",
      },
    },
    computed: {
      styles() {
        return {
          "--width": this.width,
          "--height": this.height,
        };
      },
    },
    methods: {},
  };
</script>

<style scoped lang="scss">
  @media screen {
    .print-sheet {
      width: var(--width);
      min-width: var(--width);
      max-width: var(--width);
      height: var(--height);
      min-height: var(--height);
      max-height: var(--height);
      margin-top: 5mm;
      margin-bottom: 5mm;
      margin-left: auto;
      margin-right: auto;
      background: white;
      box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
    }
  }
</style>

<style lang="scss">
</style>