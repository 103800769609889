<template>
  <v-dialog v-model="show" persistent width="200px">
    <v-card color="primary" dark>
      <v-card-title> 通信中... </v-card-title>
      <v-card-text>
        <img :src="_img">
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import loadingImage from "@/assets/img/loading.gif";
  export default {
    components: {},
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _img: {
        get() {
          return loadingImage;
        },
      },
    },
    methods: {},
  };
</script>

<style scoped>
</style>