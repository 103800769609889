<template>
  <div
    class="outline-box"
    :class="{ tablet: _isTablet, 'outline-box_error': error }"
    :style="styles"
  >
    <slot name="item"></slot>
  </div>
</template>

<script>
  export default {
    props: {
      width: {
        type: String,
        default: "auto",
      },
      maxWidth: {
        type: String,
        default: "auto",
      },
      minWidth: {
        type: String,
        default: "auto",
      },
      height: {
        type: String,
        default: "auto",
      },
      maxHeight: {
        type: String,
        default: "auto",
      },
      minHeight: {
        type: String,
        default: "auto",
      },
      error: {
        type: Boolean,
        default: false,
      },
      tablet: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _isTablet: {
        get() {
          return this.tablet;
        },
      },
      styles() {
        return {
          "--width": this.width,
          "--max-width": this.maxWidth,
          "--min-width": this.minWidth,
          "--height": this.height,
          "--max-height": this.maxHeight,
          "--min-height": this.minHeight,
        };
      },
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  .outline-box {
    border: 1px solid hsl(0, 0%, 77%);

    --width: auto;
    --max-width: auto;
    --min-width: auto;
    --height: auto;
    --max-height: auto;
    --min-height: auto;

    width: var(--width);
    max-width: var(--max-width);
    min-width: var(--min-width);
    height: var(--height);
    max-height: var(--max-height);
    min-height: var(--min-height);

    &.tablet {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    &.outline-box_error {
      border: 2px solid #ff5252;
      background-color: #ff525226;

      color: #fc0000;
    }
  }
</style>