<template>
  <div class="d-flex align-center" :class="{'text-tablet': tablet}">
    <div class="pr-2">社員かな</div>
    <v-scroll-x-transition mode="out-in">
      <div v-if="_filterSelected1 === null" key="filter-2">
        <h-toggle
          :items="constSearchInitialTable"
          :selected="_filterSelected1"
          @update:selected="filterDataSelectedChanged($event)"
          type="btn"
          itemWidth="70px"
          prefixKey="group1_"
          large
          wrap
          :tablet="tablet"
        />
      </div>
      <div v-else key="filter-3">
        <h-toggle
          :items="_filterItems2"
          :selected="_filterSelected2"
          @update:selected="filterDataSelectedChanged($event)"
          type="btn"
          itemWidth="70px"
          prefixKey="group2_"
          large
          wrap
          :tablet="tablet"
        />
      </div>
    </v-scroll-x-transition>
  </div>
</template>

<script>
  import hToggle from "@/components/molecules/toggle/hToggle.vue";
  import { SEARCH_INITIAL_TABLE_1 } from "@/common/table";
  import { SEARCH_INITIAL_TABLE_2 } from "@/common/table";
  import { SEARCH_INITIAL_BACK_ITEM } from "@/common/const";
  import typeHelper from "@/common/type.js";

  export default {
    components: {
      hToggle,
    },
    data: function () {
      return {
        constSearchInitialTable: SEARCH_INITIAL_TABLE_1,
      };
    },
    props: {
      filterSelected1: {
        type: String,
        default: null,
      },
      filterSelected2: {
        type: [String, Number],
        default: null,
      },
      tablet: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _filterSelected1: {
        get() {
          return this.filterSelected1;
        },
        set(value) {
          this.$emit("update:filterSelected1", value);
        },
      },
      _filterSelected2: {
        get() {
          return this.filterSelected2;
        },
        set(value) {
          this.$emit("update:filterSelected2", value);
        },
      },
      _filterItems2: {
        get() {
          let items = [];
          if (typeHelper.isNull(this._filterSelected1)) {
            items.push(SEARCH_INITIAL_BACK_ITEM);
            return items;
          } else {
            let rec = SEARCH_INITIAL_TABLE_2[this._filterSelected1];
            if (!typeHelper.isNull(rec)) {
              items = rec.concat(items);
            }
            items.push(SEARCH_INITIAL_BACK_ITEM);
            return items;
          }
        },
      },
    },
    methods: {
      filterDataSelectedChanged(value) {
        if (value === "back") {
          this._filterSelected1 = null;
          this._filterSelected2 = null;
        } else {
          if (typeHelper.isNull(this._filterSelected1)) {
            this._filterSelected1 = value;
            this._filterSelected2 = null;
          } else {
            this._filterSelected2 = value;
          }
        }
      },
    },
  };
</script>

<style scoped>
</style>
