<template>
  <div class="group-box">
    <fieldset
      class="group-box_fieldset"
      :class="{ 'group-box_fieldset-not-Label': !_hasLabel }"
      v-if="!flat"
    >
      <legend><span>&#8203;</span>{{ label }}<span>&#8203;</span></legend>
    </fieldset>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      label: {
        type: String,
        default: null,
      },
      flat: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _hasLabel: {
        get() {
          return (
            this.label !== undefined &&
            this.label !== null &&
            this.label.length > 0
          );
        },
      },
    },
    methods: {},
  };
</script>

<style scoped lang="scss">
  .group-box {
    position: relative;
    padding-top: 6px;
    padding-bottom: 5px;
    // base: vuetify outline text
    .group-box_fieldset {
      position: absolute;
      top: -5px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      padding-left: 8px;

      border-radius: 4px;
      border-collapse: collapse;
      border-color: currentColor;
      border-style: solid;
      border-width: 1px;
      color: rgba(0, 0, 0, 0.38);

      &:not(.group-box_fieldset-not-Label) {
        legend span {
          padding: 2px;
        }
      }

      legend {
        color: rgba(0, 0, 0, 0.6);
        line-height: 12px;
        font-size: 13.5px; // 18px * 0.75 (vuetify outline text scale 0.75 考慮)
      }
    }
  }
</style>