import { default as qs } from "qs";

var storage = sessionStorage;

export const QUERY_SORT_KEY = Object.freeze({
    SORT_BY: "sortBy",
    SORT_DESC: "sortDesc",
})

export default {
    getItem(key) {
        return qs.parse(storage.getItem(key));
    },
    setItem(key, value) {
        const query = qs.stringify(value, { skipNulls: true });
        storage.setItem(key, query);
        return qs.parse(query);
    },
    removeItem(key) {
        return storage.removeItem(key);
    },
    getValue(query, key, defaultValue) {
        return key in query ? query[key] : defaultValue;
    },
}