<template>
  <h-select-List
    :items="listItems"
    :selected.sync="_selected"
    :itemWidth="tablet ? '300px' : '220px'"
    wrap
    :tablet="tablet"
  />
</template>

<script>
  import hSelectList from "@/components/molecules/selectlist/hSelectList.vue";
  export default {
    components: {
      hSelectList,
    },
    data() {
      return {};
    },
    props: {
      listItems: {
        // [{
        //  text: string | number
        //  value: string | number
        //}]
        type: Array,
        default() {
          return [];
        },
      },
      selected: {
        type: [String, Number],
        default: null,
      },
      tablet: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _selected: {
        get() {
          return this.selected;
        },
        set(value) {
          this.$emit("update:selected", value);
        },
      },
    },
    methods: {},
  };
</script>

<style scoped>
</style>