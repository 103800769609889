<template>
  <page-footer>
    <template v-slot:leftContent>
      <outline-select
        label="表示倍率"
        fixed-label
        fixed-width="100px"
        :items="scaleItemsData"
        :selected.sync="_scale"
      />
    </template>
    <template v-slot:centerContent>
      <div class="d-flex align-center">
        <btn
          btn-text="<"
          class="mr-3"
          type="primary"
          @btnClick="$emit('prev-btn-click')"
          small
        >
        </btn>
        <div class="mr-3">{{ currentPageNo }} / {{ maxPageNo }}</div>
        <btn
          btn-text=">"
          class=""
          type="primary"
          @btnClick="$emit('next-btn-click')"
          small
        >
        </btn>
      </div>
    </template>
    <template v-slot:rightContent>
      <btn
        btn-text="印刷"
        class="mr-3"
        type="primary"
        @btnClick="$emit('print-btn-click')"
      >
      </btn>
      <btn btn-text="閉じる" type="error" @btnClick="$emit('close-btn-click')">
      </btn>
    </template>
  </page-footer>
</template>

<script>
  import pageFooter from "@/components/molecules/footer/pageFooter.vue";
  import btn from "@/components/atoms/button/btn.vue";
  import outlineSelect from "@/components/atoms/input/outlineSelect.vue";
  export default {
    components: {
      pageFooter,
      btn,
      outlineSelect,
    },
    data() {
      return {
        scaleItemsData: [
          { text: "x1.0", value: 1 },
          { text: "x0.9", value: 0.9 },
          { text: "x0.8", value: 0.8 },
          { text: "x0.7", value: 0.7 },
          { text: "x0.6", value: 0.6 },
          { text: "x0.5", value: 0.5 },
        ],
        scaleData: 1,
      };
    },
    props: {
      maxPageNo: {
        type: Number,
        default: 0,
      },
      currentPageNo: {
        type: Number,
        default: 0,
      },
      scale: {
        type: Number,
        default: 1,
      },
    },
    computed: {
      _scale: {
        get() {
          return this.scale;
        },
        set(value) {
          this.$emit("update:scale", value);
        },
      },
    },
    methods: {},
  };
</script>

<style scoped>
  .input-outline-select{
    background-color: white;
  }
</style>