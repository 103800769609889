<template>
  <v-footer
    padless
    app
    inset
    color="rgba(124,197,232,0.5)"
    class="pa-2 footer-page-footer print-no"
  >
    <v-row no-gutters>
      <v-col class="d-flex justify-start">
        <slot name="leftContent"></slot>
      </v-col>
      <v-col class="d-flex justify-center">
        <slot name="centerContent"></slot>
      </v-col>
      <v-col class="d-flex justify-end">
        <slot name="rightContent"></slot>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
  export default {
    components: {},
    data() {
      return {};
    },
    props: {},
    computed: {},
    methods: {},
  };
</script>

<style scoped>
  .footer-page-footer {
    height: 52px;
    max-height: 52px;
  }
</style>