import { default as typeHelper } from "./type.js";
import { DAY_OF_WEEK_STRING } from "./const.js";

export const FORMAT_DATE_STRING = {
    TYPE: Object.freeze({
        YYYYMMDD: 1,                    // YYYYMMDD
        YYYY_MM_DD: 2,                  // YYYY-MM-DD
        YYYY_MM_DD_JP: 3,               // YYYY年MM月DD日
        YYYY_MM_DD_WEEK: 4,             // YYYY-MM-DD(aaa)
        YYYY_MM: 5,                     // YYYY-MM
        YYYY_MM_JP: 6,                  // YYYY年MM月
        MM_DD_SLA: 7,                   // MM/DD
        YYYY_MM_DD_HH_MM_SS: 10,        // YYYY-MM-DD HH:MM:SS
        YYYY_MM_DD_JP_HH_MM: 11,        // YYYY年MM月DD日 HH:MM
        MM_DD_WEEK_SLA: 12,             // MM/DD(aaa)
        YYYY_MM_DD_WEEK_HH_MM_SLA: 13, // YYYY/MM/DD(aaa) HH:MM
        REQUEST_FORMAT: 99,             // ISO8601に基づいたフォーマット YYYY-MM-DDTHH:MM:SS+00:00
    })
}

export default {
    getYearString(value, zeroPadding = true) {
        if (!typeHelper.isDate(value)) {
            return "";
        }

        if (zeroPadding) {
            return ("0000" + value.getFullYear()).slice(-4);
        }
        return value.getFullYear().toString();
    },
    getMonthString(value, zeroPadding = true) {
        if (!typeHelper.isDate(value)) {
            return "";
        }

        if (zeroPadding) {
            return ("00" + (value.getMonth() + 1)).slice(-2);
        }
        return (value.getMonth() + 1).toString();
    },
    getDayString(value, zeroPadding = true) {
        if (!typeHelper.isDate(value)) {
            return "";
        }

        if (zeroPadding) {
            return ("00" + value.getDate()).slice(-2);
        }
        return value.getDate().toString();
    },
    getHourString(value, zeroPadding = true) {
        if (!typeHelper.isDate(value)) {
            return "";
        }

        if (zeroPadding) {
            return ("00" + value.getHours()).slice(-2);
        }
        return value.getHours().toString();
    },
    getMinutesString(value, zeroPadding = true) {
        if (!typeHelper.isDate(value)) {
            return "";
        }

        if (zeroPadding) {
            return ("00" + value.getMinutes()).slice(-2);
        }
        return value.getMinutes().toString();
    },
    getSecondsString(value, zeroPadding = true) {
        if (!typeHelper.isDate(value)) {
            return "";
        }

        if (zeroPadding) {
            return ("00" + value.getSeconds()).slice(-2);
        }
        return value.getSeconds().toString();
    },
    getTimezoneString(value) {
        if (!typeHelper.isDate(value)) {
            return "";
        }

        const offset = value.getTimezoneOffset();
        const sign = offset <= 0 ? '+' : '-';
        const hour = Math.floor(Math.abs(offset) / 60);
        const minutes = Math.abs(offset) % 60;

        return sign + ("00" + hour).slice(-2) + ':' + ("00" + minutes).slice(-2);
    },
    formatString(value, formatType = FORMAT_DATE_STRING.TYPE.YYYYMMDD) {
        let dispValue = "";

        if (typeHelper.isDate(value)) {
            switch (formatType) {
                case FORMAT_DATE_STRING.TYPE.YYYYMMDD:
                    dispValue = this.getYearString(value) + this.getMonthString(value) + this.getDayString(value);
                    break;
                case FORMAT_DATE_STRING.TYPE.YYYY_MM_DD:
                    dispValue = this.getYearString(value) + "-" + this.getMonthString(value) + "-" + this.getDayString(value);
                    break;
                case FORMAT_DATE_STRING.TYPE.YYYY_MM_DD_JP:
                    dispValue = this.getYearString(value) + "年" + this.getMonthString(value) + "月" + this.getDayString(value) + "日";
                    break;
                case FORMAT_DATE_STRING.TYPE.YYYY_MM_DD_WEEK:
                    dispValue = this.getYearString(value) + "-" + this.getMonthString(value) + "-" + this.getDayString(value) + "(" + DAY_OF_WEEK_STRING[value.getDay()] + ")";
                    break;
                case FORMAT_DATE_STRING.TYPE.YYYY_MM_DD_HH_MM_SS:
                    dispValue = this.getYearString(value) + "-" + this.getMonthString(value) + "-" + this.getDayString(value) + " " + this.getHourString(value) + ":" + this.getMinutesString(value) + ":" + this.getSecondsString(value);
                    break;
                case FORMAT_DATE_STRING.TYPE.YYYY_MM_DD_JP_HH_MM:
                    dispValue = this.getYearString(value) + "年" + this.getMonthString(value) + "月" + this.getDayString(value) + "日 " + this.getHourString(value) + ":" + this.getMinutesString(value);
                    break;
                case FORMAT_DATE_STRING.TYPE.YYYY_MM:
                    dispValue = this.getYearString(value) + "-" + this.getMonthString(value);
                    break;
                case FORMAT_DATE_STRING.TYPE.YYYY_MM_JP:
                    dispValue = this.getYearString(value) + "年" + this.getMonthString(value) + "月";
                    break;
                case FORMAT_DATE_STRING.TYPE.MM_DD_SLA:
                    dispValue = this.getMonthString(value) + "/" + this.getDayString(value);
                    break;
                case FORMAT_DATE_STRING.TYPE.MM_DD_WEEK_SLA:
                    dispValue = this.getMonthString(value) + "/" + this.getDayString(value) + "(" + DAY_OF_WEEK_STRING[value.getDay()] + ")";
                    break;
                case FORMAT_DATE_STRING.TYPE.YYYY_MM_DD_WEEK_HH_MM_SLA:
                    dispValue = this.getYearString(value) + "/" + this.getMonthString(value) + "/" + this.getDayString(value) + "(" + DAY_OF_WEEK_STRING[value.getDay()] + ") " + this.getHourString(value) + ":" + this.getMinutesString(value);
                    break;
                case FORMAT_DATE_STRING.TYPE.REQUEST_FORMAT:
                    dispValue = this.getYearString(value) + "-" + this.getMonthString(value) + "-" + this.getDayString(value) + "T" + this.getHourString(value) + ":" + this.getMinutesString(value) + ":" + this.getSecondsString(value) + this.getTimezoneString(value);
                    break;
            }
        }
        return dispValue;
    },
    afterOrEqual(startDate, endDate) {
        if (startDate.getTime() >= endDate.getTime()) {
            return true
        } else {
            return false
        }
    },
    today() {
        let now = new Date();
        now.setHours(0, 0, 0, 0);
        return now;
    },
    diffInDays($src, $dest) {
        var diffTime = $src.getTime() - $dest.getTime();
        return Math.floor(diffTime / 86400000);   // (1000 * 60 * 60 * 24));
    }
}