<template>
  <v-card
    outlined
    class="h_select_item rounded-0 d-flex justify-center align-center px-2"
    :class="{ h_select_item_active: _active, tablet: _isTablet }"
    @click="clickEvent()"
  >
    <v-icon
      v-if="_active"
      :size="_isTablet ? '24px' : '20px'"
      color="blue lighten-1"
    >
      mdi-check-circle-outline
    </v-icon>
    <v-icon v-if="!_active" :size="_isTablet ? '24px' : '20px'">
      mdi-checkbox-blank-circle-outline
    </v-icon>
    <div
      class="h_select_item_text pl-2"
      v-bind:class="{ h_select_item_active_text: _active }"
    >
      {{ text }}
    </div>
  </v-card>
</template>

<script>
  export default {
    props: {
      active: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        default: "",
      },
      tablet: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _active: {
        get() {
          return this.active;
        },
      },
      _isTablet: {
        get() {
          return this.tablet;
        },
      },
    },
    methods: {
      // ダイアログ閉じる
      clickEvent() {
        this.$emit("itemClick");
      },
    },
  };
</script>

<style scoped>
  .h_select_item {
    height: 2.8rem;
    max-height: 2.8rem;
    margin: 0.5px;
  }

  .h_select_item.tablet {
    font-size: 1.4rem;
    height: 3.7rem;
    max-height: 3.7rem;
    margin: 0.5px;
    line-height: 1.8rem;
  }

  .h_select_item div {
    width: 100%;
  }

  .h_select_item_text {
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(0, 0, 0, 0.87);
    max-height: inherit;
  }

  .h_select_item_active_text {
    color: #42a5f5;
  }

  .h_select_item_active {
    background-color: #e7f5ff !important;
    border: thin solid rgba(0, 149, 255, 0.679);
  }
</style>