<template>
  <validation-provider
    :rules="_rules"
    :name="label"
    :disabled="readonly || disabled"
    v-slot="{ errors }"
    slim
    :vid="vid"
  >
    <v-select
      v-model="_selected"
      :items="items"
      :label="label"
      :placeholder="_placeholder"
      :outlined="!readonly && !disabled"
      :filled="readonly || disabled"
      dense
      :readonly="readonly"
      :disabled="disabled"
      menu-props="auto"
      :hide-details="hideDetails"
      :append-icon="_dispDropDownIcon"
      class="input-outline-select"
      :class="[
        _textalignClass,
        { 'input-outline-select_disp-dropdown-icon': _dispDropDownIcon },
      ]"
      :style="styles"
      :multiple="multiple"
      :clearable="clearable"
      :persistent-hint="persistentHint"
      :hint="hint"
      :error-messages="errors"
    >
      <template v-slot:label v-if="required">
        <div class="d-flex">
          <div>{{ label }}</div>
          <v-chip
            small
            dark
            color="red darken-1"
            class="mx-2 mb-1 input-outline-select_required-icon"
            >必須</v-chip
          >
        </div>
      </template>
    </v-select>
  </validation-provider>
</template>

<script>
  export default {
    props: {
      vid: {
        type: String,
        default: undefined,
      },
      required: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: "",
      },
      fixedLabel: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: null,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      dispDropDownIcon: {
        type: Boolean,
        default: true,
      },
      items: {
        // [{
        //  text: string | number | object
        //  value: string | number | object
        //}]
        type: Array,
        default() {
          return [];
        },
      },
      selected: {
        // listItems:type
        type: [String, Number, Object, Array],
        default: "",
      },
      textAlign: {
        type: String,
        default: "",
      },
      width: {
        type: String,
        default: "auto",
      },
      maxWidth: {
        type: String,
        default: "auto",
      },
      minWidth: {
        type: String,
        default: "auto",
      },
      fixedWidth: {
        type: String,
        default: null,
      },
      hideDetails: {
        type: [String, Boolean],
        default: "auto",
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      rules: {
        type: String,
        default: undefined,
      },
      persistentHint: {
        type: Boolean,
        default: undefined,
      },
      hint: {
        type: String,
        default: "",
      },
    },
    computed: {
      _selected: {
        get() {
          return this.selected;
        },
        set(value) {
          this.$emit("update:selected", value);
        },
      },
      _placeholder: {
        get() {
          if (this.fixedLabel) {
            return " ";
          }
          return this.placeholder;
        },
      },
      _textalignClass: {
        get() {
          let textAlign = "";
          switch (this.textAlign) {
            case "left":
              textAlign = "input-outline-select_align-left";
              break;
            case "center":
              textAlign = "input-outline-select_align-center";
              break;
            case "right":
              textAlign = "input-outline-select_align-right";
              break;
            default:
              break;
          }
          return textAlign;
        },
      },
      _dispDropDownIcon: {
        get() {
          if (this.dispDropDownIcon && !this.disabled && !this.readonly) {
            return "$dropdown";
          } else {
            return "";
          }
        },
      },
      _rules: {
        get() {
          if (this.readonly || this.disabled) {
            return undefined;
          } else {
            return this.rules;
          }
        },
      },
      styles() {
        return {
          "--width": this.fixedWidth === null ? this.width : this.fixedWidth,
          "--max-width":
            this.fixedWidth === null ? this.maxWidth : this.fixedWidth,
          "--min-width":
            this.fixedWidth === null ? this.minWidth : this.fixedWidth,
        };
      },
    },
  };
</script>

<style scoped lang="scss">
  .input-outline-select {
    width: var(--width);
    max-width: var(--max-width);
    min-width: var(--min-width);

    &.input-outline-select_disp-dropdown-icon {
      ::v-deep .v-input__control .v-input__slot {
        padding-right: 4px;
      }
    }

    .input-outline-select_required-icon {
      height: inherit !important;
    }

    ::v-deep .v-select__selections input {
      padding: 0;
    }

    ::v-deep .v-input__append-inner {
      .v-input__icon {
        margin-top: 1px;
        width: 16px;
        min-width: 16px;

        button {
          font-size: 18px;
        }
      }
    }
  }

  .theme--light.v-text-field.v-input--is-disabled
    ::v-deep
    .v-input__slot::before {
    border-image: none !important;
  }

  .theme--light.v-select ::v-deep .v-select__selection--disabled {
    color: rgba(0, 0, 0, 0.87);
  }

  .input-outline-select_align-right {
    ::v-deep .v-select__selection {
      max-width: 100%;
      width: 100%;
      text-align: right;
    }
  }

  .input-outline-select_align-center {
    ::v-deep .v-select__selection {
      max-width: 100%;
      width: 100%;
      text-align: center;
    }
  }

  .input-outline-select_align-left {
    ::v-deep .v-select__selection {
      max-width: 100%;
      width: 100%;
      text-align: left;
    }
  }
</style>