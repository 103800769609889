<template>
  <outline-box
    :width="width"
    :max-width="maxWidth"
    :min-width="minWidth"
    :height="height"
    :max-height="maxHeight"
    :min-height="minHeight"
    :tablet="tablet"
    error
  >
    <template v-slot:item>
      <div class="pa-2">
        <div v-if="_hasTitle">{{ title }}</div>
        <ul v-if="messages.length">
          <li v-for="(message, index) in messages" :key="index">
            {{ message }}
          </li>
        </ul>
      </div>
    </template>
  </outline-box>
</template>
<script>
  import outlineBox from "@/components/atoms/box/outlineBox.vue";
  export default {
    components: {
      outlineBox,
    },
    data() {
      return {};
    },
    props: {
      width: {
        type: String,
        default: "auto",
      },
      maxWidth: {
        type: String,
        default: "auto",
      },
      minWidth: {
        type: String,
        default: "auto",
      },
      height: {
        type: String,
        default: "auto",
      },
      maxHeight: {
        type: String,
        default: "auto",
      },
      minHeight: {
        type: String,
        default: "auto",
      },
      tablet: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: null,
      },
      messages: {
        type: Array,
        default: function () {
          return [];
        },
      },
    },
    computed: {
      _hasTitle: {
        get() {
          if (this.title === null || this.title.length === 0) {
            return false;
          }

          return true;
        },
      },
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
</style>