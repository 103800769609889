<template>
  <div class="print-preview" :style="_printPreviewStyle">
    <slot name="printSheets"></slot>
    <print-preview-footer
      :max-page-no="maxPageNo"
      :current-page-no="currentPageNo"
      :scale.sync="scaleData"
      @print-btn-click="$emit('print-btn-click')"
      @close-btn-click="$emit('close-btn-click')"
      @next-btn-click="$emit('next-btn-click')"
      @prev-btn-click="$emit('prev-btn-click')"
    >
    </print-preview-footer>
  </div>
</template>

<script>
  import printPreviewFooter from "@/components/molecules/print/printPreviewFooter.vue";
  export default {
    components: {
      printPreviewFooter,
    },
    data() {
      return {
        scaleData: 1,
      };
    },
    props: {
      maxPageNo: {
        type: Number,
        default: 0,
      },
      currentPageNo: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      _printPreviewStyle(){
        return {"--scale": this.scaleData}
      }
    },
    methods: {},
  };
</script>

<style scoped lang="scss">
  @media print {
    .print-preview > :not(.print-sheet) {
      display: none;
    }

    .print-preview > .print-sheet {
      page-break-after: always;
      margin: 0mm;
    }

    .print-preview > .print-sheet:last-child {
      page-break-after: auto;
    }
  }

  @media screen {
    .print-preview {
      background: #676767;
      padding: 4px;
      min-width: fit-content;
    }

    .print-preview > .print-sheet {
      transform: scale(var(--scale));
      transform-origin: top left;
    }
  }
</style>

<style lang="scss">
</style>
