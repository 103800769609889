<template>
  <div class="page-layout">
    <slot name="title"></slot>
    <div class="page-contents px-4 py-2">
      <slot name="contents"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {};
    },
    props: {},
    methods: {},
  };
</script>

<style scoped lang="scss">
  .page-layout {
    height: 100%;
    width: 100%;
  }
</style>