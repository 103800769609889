<template>
  <div
    class="htoggle"
    :class="{
      tablet: _isTablet,
    }"
  >
    <group-box :label="label" :flat="flat">
      <v-item-group
        v-model="_selected"
        :mandatory="mandatory"
        :multiple="multiple"
        class="htoggle_item-group"
      >
        <div
          class="d-flex justify-left flex-row mt-n2"
          :class="{ 'flex-wrap': wrap }"
        >
          <v-item
            v-for="item in items"
            :key="prefixKey !== null ? prefixKey + item.value : item.value"
            :value="item.value"
            v-slot:default="{ active, toggle }"
          >
            <h-toggle-item
              :item-text="item.text"
              :item-active="active"
              :type="type"
              @item-click="toggle"
              :tablet="tablet"
              :large="large"
              :style="{ width: itemWidth }"
              :readonly="readonly"
              :disabled="disabled"
              class="px-1 mt-2 htoggle_item"
            >
            </h-toggle-item>
          </v-item>
        </div>
      </v-item-group>
    </group-box>
  </div>
</template>

<script>
  import groupBox from "@/components/atoms/group/groupBox.vue";
  import hToggleItem from "@/components/atoms/toggle/hToggleItem.vue";
  export default {
    components: {
      groupBox,
      hToggleItem,
    },
    props: {
      items: {
        // [{
        //  text: string | number
        //  value: string | number
        //}]
        type: Array,
        default() {
          return [];
        },
      },
      selected: {
        type: [String, Number, Array],
        default: null,
      },
      itemWidth: {
        type: String,
        default: "auto",
      },
      wrap: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: "default",
      },
      mandatory: {
        type: Boolean,
        default: false,
      },
      flat: {
        type: Boolean,
        default: false,
      },
      tablet: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      prefixKey: {
        type: String,
        default: null,
      },
      label: {
        type: String,
        default: null,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _selected: {
        get() {
          return this.selected;
        },
        set(value) {
          this.$emit("update:selected", value);
          if (this.multiple) {
            let selectedItems = [];
            value.forEach ((valueItem) => {
              let selectedItem = this.items.find((item) => item.value === valueItem);
              selectedItems.push(selectedItem);
            });
            this.$emit("update:selectedItem", selectedItems);
          } else {
            let selectedItem = this.items.find((item) => item.value === value);
            this.$emit("update:selectedItem", selectedItem);
          }
        },
      },
      _hasLabel: {
        get() {
          return (
            this.label !== undefined &&
            this.label !== null &&
            this.label.length > 0
          );
        },
      },
      _isTablet: {
        get() {
          return this.tablet;
        },
      },
    },
    methods: {},
  };
</script>

<style scoped lang="scss">
</style>
