<template>
  <v-expansion-panel>
    <v-card outlined>
      <v-expansion-panel-header :color="_color" class="white--text" :class="{'text-tablet': tablet}">
        <span>
          {{ title }}
          <slot name="hint"></slot>
        </span>
      </v-expansion-panel-header>
      <v-divider></v-divider>
      <v-expansion-panel-content>
        <slot name="content"></slot>
      </v-expansion-panel-content>
    </v-card>
  </v-expansion-panel>
</template>
<script>
  export default {
    components: {},
    data() {
      return {};
    },
    props: {
      title: {
        type: String,
        default: "",
      },
      type: {
        type: String,
        default: "",
      },
      tablet: {
        type: Boolean,
        default: false,
      },
      hint: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _color() {
        let color = "#083388";
        switch (this.type) {
          case "admin":
            color = "#118605";
            break;
          default:
            break;
        }
        return color;
      }
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  ::v-deep .v-expansion-panel-header__icon i {
    color: #ffff !important;
  }
</style>