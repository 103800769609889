<template>
  <div>
    <v-card
      :disabled="disabled || readonly"
      :outlined="isActive"
      :color="isActive ? '' : 'grey lighten-3'"
      active-class=""
      class="toggle_h_toggle_radio_item d-flex justify-center align-stretch"
      :class="{
        toggle_h_toggle_radio_item_active: isActive,
        tablet: _isTablet,
        large: large,
      }"
      @click="$emit('item-click')"
    >
      <!-- type:btn -->
      <div
        v-if="type === 'btn'"
        class="d-flex flex-row align-center text-center"
      >
        <div
          class="toggle_h_toggle_radio_item_text"
          v-bind:class="{ toggle_h_toggle_radio_item_active_text: isActive }"
        >
          {{ itemText }}
        </div>
      </div>
      <!-- type:icon -->
      <div
        v-else-if="type === 'icon'"
      >
        <v-icon
          v-if="isActive"
          color="#e7f5ff"
          :size="_isTablet ? '26px' : large ? '20px' : '18px'"
        >
          mdi-check-circle-outline
        </v-icon>
        <v-icon
          v-if="!isActive"
          :size="_isTablet ? '26px' : large ? '20px' : '18px'"
        >
          mdi-checkbox-blank-circle-outline
        </v-icon>
      </div>
      <!-- type:default -->
      <div class="d-flex flex-row align-center text-left" v-else>
        <v-icon
          v-if="isActive"
          color="#e7f5ff"
          :size="_isTablet ? '26px' : large ? '20px' : '18px'"
        >
          mdi-check-circle-outline
        </v-icon>
        <v-icon
          v-if="!isActive"
          :size="_isTablet ? '26px' : large ? '20px' : '18px'"
        >
          mdi-checkbox-blank-circle-outline
        </v-icon>
        <div
          class="toggle_h_toggle_radio_item_text pl-1"
          v-bind:class="{ toggle_h_toggle_radio_item_active_text: isActive }"
        >
          {{ itemText }}
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    props: {
      itemActive: {
        type: Boolean,
        default: false,
      },
      itemText: {
        type: String,
        default: "",
      },
      type: {
        type: String,
        default: "default",
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      tablet: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isActive: {
        get() {
          return this.itemActive;
        },
      },
      _isTablet: {
        get() {
          return this.tablet;
        },
      },
    },
    methods: {
    },
  };
</script>

<style scoped>
  .toggle_h_toggle_radio_item {
    height: 24px;
    max-height: 24px;
    margin: 0.5px;
    padding: 1px 4px 1px 4px;
  }

  .toggle_h_toggle_radio_item.large {
    height: 36px;
    max-height: 36px;
    line-height: 1.1rem;
  }

  .toggle_h_toggle_radio_item.tablet {
    height: 3.3rem;
    max-height: 3.3rem;
    line-height: 1.5rem;
    margin: 0.5px;
  }

  .toggle_h_toggle_radio_item_text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875rem;
    padding-top: 1px;
  }

  .large .toggle_h_toggle_radio_item_text {
    font-size: 1.1rem;
  }

  .tablet .toggle_h_toggle_radio_item_text {
    font-size: 1.5rem;
  }

  .toggle_h_toggle_radio_item_active_text {
    color: #e7f5ff;
  }

  .toggle_h_toggle_radio_item_active {
    padding: 0px 3px 0px 3px;
    background-color: rgba(0, 149, 255, 0.679) !important;
    border: thin solid rgba(0, 149, 255, 0.679);

    box-shadow: 0px 3px 2px -4px rgba(0, 0, 0, 0.15) inset,
      0.5px 1px 2px 0px rgba(0, 0, 0, 0.2) inset,
      0px 1px 5px 0px rgba(0, 0, 0, 0.1) inset;
  }
</style>