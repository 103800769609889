<template>
  <v-item-group
    v-model="_selected"
    class="d-flex justify-left flex-row"
    v-bind:class="{ 'flex-wrap': wrap }"
    :multiple="multiple"
  >
    <v-item
      v-for="item in items"
      :key="item.text + item.value"
      :value="item.value"
      v-slot:default="{ active, toggle }"
    >
      <div :style="{ width: itemWidth }">
        <h-select-list-item
          :text="item.text"
          :active="active"
          @itemClick="toggle"
          :tablet="tablet"
        >
        </h-select-list-item>
      </div>
    </v-item>
  </v-item-group>
</template>

<script>
  import hSelectListItem from "@/components/atoms/selectlist/hSelectListItem.vue";
  export default {
    components: {
      hSelectListItem,
    },
    props: {
      // [{
      //  text: string | number
      //  value: string | number
      //}]
      items: {
        type: Array,
        default() {
          return [];
        },
      },
      selected: {
        type: [String, Number, Array],
        default: null,
      },
      itemWidth: {
        type: String,
        default: "auto",
      },
      wrap: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      tablet: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _selected: {
        get() {
          return this.selected;
        },
        set(value) {
          this.$emit("update:selected", value);
          if (this.multiple) {
            let selectedItems = this.items.filter((item) => {
              return value.includes(item.value);
            });
            this.$emit("update:selectedItem", selectedItems);
          } else {
            let selectedItem = this.items.find((item) => item.value === value);
            this.$emit("update:selectedItem", selectedItem);
          }
        },
      },
    },
    methods: {},
  };
</script>

<style scoped>
</style>