import Vue from 'vue';
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate';

import ja from 'vee-validate/dist/locale/ja.json'
import * as rules from 'vee-validate/dist/rules'

// コンポーネントの登録
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// エラーメッセージの日本語化
localize('ja', ja)

// バリデーションルール
for (const rule in rules) {
    extend(rule, rules[rule])
}

extend("after_or_equal", {
    params: ['target'],
    validate(value, { target }) {
        return value > target;
    },
    message: '{_field_}は{target}以降でなければなりません。'
});

extend("hiragana", {
    validate(value) {
        return /^[\u3041-\u3096ー～ 　]+$/.test(value)
    },
    message: '{_field_}は全角ひらがなのみです。'
});

extend("collection_max_length", {
    params: ['maxLength'],

    validate(value, { maxLength }) {
        if (!Array.isArray(value)) {
            return false
        }

        return value.length <= maxLength
    },

    message(field, { maxLength }) {
        return `${field}は${maxLength}つまでしか選択することができません。`
    },
});

extend("lte", {
    params: ['target'],
    validate(value, { target }) {
        return value <= target;
    },
    message: '{_field_}は{target}以下でなければなりません。'
});
