export const APP = {
    WORKTIME_HOUR_MAX: 47,
    LIST_MIN_HEIGHT: 200,
    LIST_BOTTOM_PADDING: 10,
    APP_HEADER_SIZE: 48,
    LOGIN_URL: process.env.BASE_URL + 'login',
    HOME_URL: process.env.BASE_URL + 'home',
    PRINT_PREVIEW_URL: process.env.BASE_URL + 'print/preview',
}

export const OVERTIME = {
    TYPE: Object.freeze({
        OVERTIME: 1,
        HOLIDAY: 2,
    })
}

export const FORMAT_LABEL_FORMAT = {
    TYPE: Object.freeze({
        STRING: 0,                  // raw
        YYYYMMDD: 1,                // YYYYMMDD
        YYYY_MM_DD: 2,              // YYYY-MM-DD
        YYYY_MM_DD_JP: 3,           // YYYY年MM月DD日
        YYYY_MM_DD_WEEK: 4,         // YYYY-MM-DD(aaa)
        YYYY_MM_JP: 5,              // YYYY年MM月
        MM_DD_SLA: 6,               // MM/DD
        MM_DD_WEEK_SLA: 7,          // MM/DD(aaa)
        YYYY_MM_DD_HH_MM_SS: 10,    // YYYY-MM-DD HH:MM:SS
        HHMM: 50,                   // HHMM
        HH_MM: 51,                  // HH:MM
        HH_MM_JP: 52,               // HH時MM分
        NUMBER_COMMNA: 100,         // 1,000
        NUMBER_COMMNA_THOUSAND: 110, // 1,00(000)
    })
}

export const DATE_INPUT_FORMAT = {
    TYPE: Object.freeze({
        YYYYMMDD: 1,          // YYYYMMDD
        YYYYMM: 2,          // YYYYMM
        YYYY: 3,          // YYYY
    })
}

export const EXTRACT_DATE_TYPE = {
    TYPE: Object.freeze({
        DATE_RANGE: 0,
        MONTH: 1,
    }),
};

export const OVERTIME_TYPE = {
    TYPE: Object.freeze({
        OVERTIME: 1,          // 時間外
        HOLIDAY_WORK: 2,          // 休日出勤
    }),
    NAME: Object.freeze({
        OVERTIME: "時間外",
        HOLIDAY_WORK: "休日出勤",
    })
}

export const VACATION_TYPE = {
    TYPE: Object.freeze({
        ANNUAL_VACATION: 1,          // 年次休暇
        CONDOLENCE_VACATION: 2,          // 慶弔休暇
        CHILDCARE_VACATION: 3,          // 育児休暇
        NURSING_CARE_VACATION: 4,          // 介護休暇
        ALTERNATIVE_VACATION: 5,          // 代替休暇
        PAID_HOLIDAYS: 6,          // その他（有給）
        UNPAID_HOLIDAYS: 7,          //     その他（無給）
    }),
    NAME: Object.freeze({
        ANNUAL_VACATION: "年次休暇",
        CONDOLENCE_VACATION: "慶弔休暇",
        CHILDCARE_VACATION: "育児休暇",
        NURSING_CARE_VACATION: "介護休暇",
        ALTERNATIVE_VACATION: "代替休暇",
        PAID_HOLIDAYS: "その他（有給）",
        UNPAID_HOLIDAYS: "その他（無給）",
    })
}

export const VACATION_TERM_TYPE = {
    TYPE: Object.freeze({
        ONE_DAY: 1,          // 一日
        HALF_DAY: 2,          // 半日
        TERM: 3,          // 期間
    }),
    NAME: Object.freeze({
        ONE_DAY: "一日",
        HALF_DAY: "半日",
        TERM: "期間",
    })
}

export const VACATION_TERM_HALF_TYPE = {
    TYPE: Object.freeze({
        AM: 1,          // AM
        PM: 2,          // PM
    }),
    NAME: Object.freeze({
        AM: "AM",
        PM: "PM",
    })
}

export const DEPARTMENT = {
    TYPE: Object.freeze({
        DOBOKU: "1",        // 土木
        KENTIKU: "2",        // 建築
        KANKYO: "3",        // 環境
        OTHER: "4",        // その他
    }),
    NAME: Object.freeze({
        DOBOKU: "土木",
        KENTIKU: "建築",
        KANKYO: "環境",
        OTHER: "その他",
    })
}

export const STAY = {
    TYPE: Object.freeze({
        NONE: "0",        // 無し
        KENNAI: "1",        // 県内
        KENGAI: "2",        // 県外
        RITO: "3",        // 離島
    }),
    NAME: Object.freeze({
        NONE: "無し",
        KENNAI: "県内",
        KENGAI: "県外",
        RITO: "離島",
    })
}

export const SITE_FILTER_TYPE = {
    TYPE: Object.freeze({
        SITE_NAME: 1,        // 現場名称
        SITE_TANTOU: 2,        // 現場担当
        BUSHO: 3,        // 部署
    }),
    NAME: Object.freeze({
        SITE_NAME: "名称",
        SITE_TANTOU: "現場担当者",
        BUSHO: "部署",
    })
}

export const REQUEST_TYPE = {
    TYPE: Object.freeze({
        OVERTIME: 1,
        VACATION: 2,
    }),
    NAME: Object.freeze({
        OVERTIME: "残業",
        VACATION: "休暇",
    })
}

export const SUSPEND_WORK_TYPE = {
    TYPE: Object.freeze({
        NORMAL: 1,
        PART: 3,
        ALL: 2,
        AFTER_NIGHT_SHIFT: 4,
    }),
    NAME: Object.freeze({
        NORMAL: "通常",
        PART: "一部休工",
        ALL: "一日休工",
        AFTER_NIGHT_SHIFT: "夜勤明け",
    })
}

export const BREAK_TIME_WORK = {
    TYPE: Object.freeze({
        NONE: 0,
        QUARTER: 15,
        HALF: 30,
        THREEQUARTER: 45,
        HOUR: 60,
        HOURQUARTER: 75,
        HOURHALF: 90,
    }),
    NAME: Object.freeze({
        NONE: "なし",
        QUARTER: "15分",
        HALF: "30分",
        THREEQUARTER: "45分",
        HOUR: "60分",
        HOURQUARTER: "75分",
        HOURHALF: "90分",
    })
}

export const AUTHORIZER_FILTER_TYPE = {
    TYPE: Object.freeze({
        NAME: 1,            // 名称
        SITE: 2,            // 現場
        BUSHO: 3,           // 部署
    }),
    NAME: Object.freeze({
        NAME: "名称",
        SITE: "現場",
        BUSHO: "部署",
    })
}

export const EMPLOYEE_CLASS_TYPE = {
    TYPE: Object.freeze({
        A: "A",               // A
        B: "B",               // B
        C: "C",               // C
    }),
    NAME: Object.freeze({
        A: "A",
        B: "B",
        C: "C",
    })
}

export const APPROVE = {
    TYPE: Object.freeze({
        NOT_APPROVE: 0,
        APPROVED: 1,
        DISAPPROVED: 2,
    }),
}

export const SALARY_CONFIRM = {
    TYPE: Object.freeze({
        NOT_SALARY_CONFIRMED: 0,
        SALARY_CONFIRMED: 1,
    }),
}

export const NEED_CONFIRM = {
    TYPE: Object.freeze({
        NOT_NEED: 0,
        NEED: 1,
    }),
}

export const MODE = {
    TYPE: Object.freeze({
        UNKNOWN: 0,
        LIST: 1,
        ADD: 2,
        DETAIL: 3,
        EDIT: 4,
    }),
}

export const REQUEST_MODE = {
    TYPE: Object.freeze({
        CONFIRM: 0,
        APPROVE: 1,
        PROXY_APPROVE: 2,
        PROXY_ADD: 3,
    }),
}

export const REQUEST_APPROVAL_AUTHORITY_TYPE = {
    TYPE: Object.freeze({
        NONE: 0,
        APPROVAL: 1,
        NOTICE: 2,
    }),
    NAME: Object.freeze({
        NONE: "なし",
        APPROVAL: "承認",
        NOTICE: "通知",
    })
}

export const REQUEST_TARGET_TYPE = {
    TYPE: Object.freeze({
        OWN: 0,
        ALL: 1,
        DEPARTMENT: 2,
    }),
    NAME: Object.freeze({
        OWN: '個人',
        ALL: '全社員',
        DEPARTMENT: '部署内',
    })
}

export const EMPLOYEE_AUTHORITY_TYPE = {
    TYPE: Object.freeze({
        NONE: 0,
        SYSTEM_MANAGER: 1,
    }),
    NAME: Object.freeze({
        NONE: "なし",
        SYSTEM_MANAGER: "システム管理者",
    })
}

export const ARROWANCE_CALC_PATTERN_TYPE = {
    TYPE: Object.freeze({
        EXECUTIVE: 0,
        JOB_EVALUATION: 1,
    }),
}

export const PREMIUM_WAGES_TYPE = {
    TYPE: Object.freeze({
        NONE: 0,
        NOT_PREMIUM_WAGES: 1,
        TRANSFER: 2,
    }),
    NAME: Object.freeze({
        NONE: "なし",
        NOT_PREMIUM_WAGES: "有給調整対象",
        TRANSFER: "振替対象",
    })
}

export const APPROVE_TYPE = {
    TYPE: Object.freeze({
        NOT_APPROVE: 0,
        APPROVED: 1,
        DISAPPROVED: 2,
    }),
    NAME: Object.freeze({
        NOT_APPROVE: "未承認",
        APPROVED: "承認",
        DISAPPROVED: "否認",
    })
}

export const CSV_DISPLAY_TYPE = {
    TYPE: Object.freeze({
        EMPLOYEE: "employee",
        ATTENDANCE: "attendance",
        SALARY: "salary",
    })
}

export const CSV_GROUP_TYPE = {
    TYPE: Object.freeze({
        BASIC: "basic",
        ALLOWANCE: "allowance",
        DEDUCTION: "deduction",
        PAID_VACATION: "paidVacation",
        OVERTIME: "overtime",
    }),
    NAME: Object.freeze({
        BASIC: "基本情報",
        ALLOWANCE: "手当",
        DEDUCTION: "控除",
        PAID_VACATION: "有給休暇",
        OVERTIME: "残業時間",
    })
}

/** 勤怠情報出力 - 時間外内訳 - 選択項目 */
export const CSV_SELECT_OVERTIME = [
    "employeeName",
    "workDate",
    "siteName",
    "startTime",
    "endTime",
    "breaktime",
    "breakOvertime",
    "memo",
    "fixedOvertimeHourTime",
    "overtimeHourTime",
    "overOvertimeHourTime",
    "overtimeMidnightTime",
    "sundayTime",
    "sundayAdjustTime",
    "holidayTime",
    "holidayAdjustTime",
    "nightShiftTime",
    "absenceDeductionTime",
]

export const DAY_OF_WEEK_STRING = ["日", "月", "火", "水", "木", "金", "土"]
export const SEARCH_INITIAL_BACK_ITEM = Object.freeze({ value: "back", text: "←", items: [] })

export const VIEW_RANGE_TYPE = {
    TYPE: Object.freeze({
        ME: 1,
        OTHER: 2,
    })
}

export const OPERATION_TYPE = {
    TYPE: Object.freeze({
        UNAVAILABLE: 1,
        CAN_READ: 1,
        CAN_EDIT: 2,
    })
}

export const LOGIN_TYPE = {
    TYPE: Object.freeze({
        COMMON: 0,
        PLOXY: 1,
    })
}

export const SORT_NUMBER_TYPE = {
    TYPE: Object.freeze({
        CIVIL_MANAGEMENT: "civilManagement",
        CIVIL_REFERENCE: "civilReference",
        WELFARE_PENSION_MANAGEMENT: "welfarePensionManagement",
        WELFARE_PENSION_REFERENCE: "welfarePensionReference",
        UNEMPLOYMENT_MANAGEMENT: "unemploymentManagement",
        UNEMPLOYMENT_REFERENCE: "unemploymentReference",
        CORPORATE_PENSION_MANAGEMENT: "corporatePensionManagement",
        CORPORATE_PENSION_REFERENCE: "corporatePensionReference",
    }),
    NAME: Object.freeze({
        CIVIL_MANAGEMENT: "土木管理番号",
        CIVIL_REFERENCE: "土木整理番号",
        WELFARE_PENSION_MANAGEMENT: "厚年管理番号",
        WELFARE_PENSION_REFERENCE: "厚年整理番号",
        UNEMPLOYMENT_MANAGEMENT: "雇用管理番号",
        UNEMPLOYMENT_REFERENCE: "雇用整理番号",
        CORPORATE_PENSION_MANAGEMENT: "企年管理番号",
        CORPORATE_PENSION_REFERENCE: "企年整理番号",
    })
}
