<template>
  <v-dialog
    v-model="show"
    persistent
    scrollable
    :max-width="maxWidth"
    :width="width"
  >
    <v-card
      :min-width="minWidth"
      :max-height="maxHeight"
      :min-height="minHeight"
      :height="height"
      :class="[{ tablet: _isTablet }]"
      :style="_styles"
    >
      <v-card-title class="base-dialog-title">
        {{ caption }}
      </v-card-title>
      <v-card-subtitle v-if="_hasSubTitleContentsSlot">
        <slot name="subTitleContents"></slot>
      </v-card-subtitle>
      <v-divider v-if="_hasSubTitleContentsSlot"></v-divider>
      <v-card-text class="py-3" v-if="_hasContentsSlot">
        <slot name="contents"></slot>
      </v-card-text>
      <v-divider v-if="_hasActionContentsSlot"></v-divider>
      <v-card-actions v-if="_hasActionContentsSlot">
        <v-spacer></v-spacer>
        <slot name="actionContents"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    components: {},
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      maxWidth: {
        type: [String, Number],
        default: null,
      },
      minWidth: {
        type: [String, Number],
        default: null,
      },
      width: {
        type: [String, Number],
        default: null,
      },
      maxHeight: {
        type: [String, Number],
        default: null,
      },
      minHeight: {
        type: [String, Number],
        default: null,
      },
      height: {
        type: [String, Number],
        default: null,
      },
      caption: {
        type: String,
        default: "",
      },
      tablet: {
        type: Boolean,
        default: false,
      },
      titleBackgroundColor: {
        type: String,
        default: "#7cc5e8",
      },
      captititleColoron: {
        type: String,
        default: "#212121",
      },
    },
    computed: {
      _hasSubTitleContentsSlot() {
        return !!this.$slots.subTitleContents;
      },
      _hasContentsSlot() {
        return !!this.$slots.contents;
      },
      _hasActionContentsSlot() {
        return !!this.$slots.actionContents;
      },
      _isTablet: {
        get() {
          return this.tablet;
        },
      },
      _styles() {
        return {
          "--title-background-color": this.titleBackgroundColor,
          "--title-color": this.titleColor,
        };
      },

    },
    methods: {},
  };
</script>

<style scoped>
  .base-dialog-title {
    background-color: var(--title-background-color);
    color: var(--title-color);
  }

  .v-card__title + .v-card__subtitle {
    margin-top: 0px;
  }

  .tablet .v-card__title {
    line-height: 2.5rem;
  }

  .tablet .v-card__title.base-dialog-title {
    font-size: 1.5rem;
  }

  .tablet .v-card__text {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
</style>