<template>
  <v-btn
    @click="clickEvent"
    :max-width="maxWidth"
    :min-width="minWidth"
    :width="width"
    :disabled="disabled"
    :color="_color"
    class="print-no"
    :class="{ 'white--text': _isWhileText, tablet: _isTablet }"
    :loading="loading"
    :block="block"
    :small="small"
    :x-small="xSmall"
    :large="large"
    :x-large="xLarge"
    :fixed="_isFloting"
    :top="top"
    :left="left"
    :right="right"
    :bottom="bottom"
  >
    {{ btnText }}
  </v-btn>
</template>

<script>
  export default {
    props: {
      btnText: {
        type: String,
        default: "",
      },
      width: {
        type: [String, Number],
        default: null,
      },
      maxWidth: {
        type: [String, Number],
        default: null,
      },
      minWidth: {
        type: [String, Number],
        default: null,
      },
      type: {
        type: String,
        default: "default",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      block: {
        type: Boolean,
        default: false,
      },
      xSmall: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      xLarge: {
        type: Boolean,
        default: false,
      },
      tablet: {
        type: Boolean,
        default: false,
      },
      top: {
        type: Boolean,
        default: false,
      },
      left: {
        type: Boolean,
        default: false,
      },
      right: {
        type: Boolean,
        default: false,
      },
      bottom: {
        type: Boolean,
        default: false,
      },
      floting: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _color: {
        get() {
          let color = undefined;
          switch (this.type) {
            case "delete":
              color = "pink accent-2";
              break;
            case "attendanceList":
            case "detailList":
              color = "green";
              break;
            case "edit":
              color = "orange darken-2";
              break;
            case "error":
              color = "error";
              break;
            case "primary":
              color = "primary";
              break;
            case "search":
              color = "blue darken-2";
              break;
            default:
              break;
          }
          return color;
        },
      },
      _isWhileText: {
        get() {
          let ret = false;
          switch (this.type) {
            case "delete":
            case "detailList":
            case "attendanceList":
            case "edit":
            case "search":
              ret = true;
              break;
            default:
              break;
          }
          return ret;
        },
      },
      _isTablet: {
        get() {
          return this.tablet;
        },
      },
      _isFloting: {
        get() {
          return this.floting;
        },
      },
    },
    methods: {
      // ダイアログ閉じる
      clickEvent() {
        this.$emit("btnClick");
      },
    },
  };
</script>

<style scoped>
  .v-btn.v-size--default.tablet {
    font-size: 1.1rem;
  }

  .v-btn.v-size--large.tablet {
    font-size: 1.3rem;
  }

  .v-btn.v-size--x-large.tablet {
    font-size: 1.3rem;
  }
</style>
