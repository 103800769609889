<template>
  <validation-provider
    :rules="_rules"
    :name="label"
    :disabled="readonly || disabled"
    v-slot="{ errors }"
    slim
  >
    <v-textarea
      v-model="_text"
      :label="label"
      :placeholder="_placeholder"
      :outlined="!readonly && !disabled"
      :filled="readonly || disabled"
      dense
      :readonly="readonly"
      :disabled="disabled"
      :hide-details="hideDetails"
      :style="styles"
      :counter="counter"
      class="input-input-text-area"
      :class="{
        'always-show-scroll-y': alwaysShowScrollbar,
        tablet: tablet,
      }"
      :append-icon="appendIcon"
      :type="type"
      :clearable="clearable"
      :height="height"
      :row-height="rowHeight"
      :rows="rows"
      :error-messages="errors"
      no-resize
      :background-color="_backgroundColor"
    >
      <template v-slot:label v-if="required">
        <div class="d-flex">
          <div>{{ label }}</div>
          <v-chip
            small
            dark
            color="red darken-1"
            class="mx-2 mb-1 input-text-required-icon"
            >必須</v-chip
          >
        </div>
      </template>
    </v-textarea>
  </validation-provider>
</template>

<script>
  export default {
    props: {
      required: {
        type: Boolean,
        default: false,
      },
      fixedLabel: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: "",
      },
      placeholder: {
        type: String,
        default: "",
      },
      text: {
        type: String,
        default: "",
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      hideDetails: {
        type: [String, Boolean],
        default: "auto",
      },
      height: {
        type: String,
        default: "auto",
      },
      width: {
        type: String,
        default: "auto",
      },
      maxWidth: {
        type: String,
        default: "auto",
      },
      minWidth: {
        type: String,
        default: "auto",
      },
      fixedWidth: {
        type: String,
        default: null,
      },
      rules: {
        type: String,
        default: undefined,
      },
      counter: {
        type: [Boolean, Number, String],
        default: null,
      },
      appendIcon: {
        type: String,
        default: null,
      },
      type: {
        type: String,
        default: "text",
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      alwaysShowScrollbar: {
        type: Boolean,
        default: false,
      },
      rowHeight: {
        type: Number,
        default: 24,
      },
      rows: {
        type: Number,
        default: 5,
      },
      backgroundColor: {
        type: String,
        default: "white",
      },
      tablet: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _placeholder() {
        if (this.fixedLabel) {
          return " ";
        }
        return this.placeholder;
      },
      _text: {
        get() {
          return this.text;
        },
        set(value) {
          this.$emit("update:text", value);
        },
      },
      _backgroundColor: {
        get() {
          if (this.readonly || this.disabled) {
            return undefined;
          } else {
            return this.backgroundColor;
          }
        },
      },
      _rules: {
        get() {
          if (this.readonly || this.disabled) {
            return undefined;
          } else {
            return this.rules;
          }
        },
      },
      styles() {
        return {
          "--width": this.fixedWidth === null ? this.width : this.fixedWidth,
          "--max-width":
            this.fixedWidth === null ? this.maxWidth : this.fixedWidth,
          "--min-width":
            this.fixedWidth === null ? this.minWidth : this.fixedWidth,
        };
      },
    },
    methods: {},
  };
</script>

<style scoped lang="scss">
  .input-text-required-icon {
    height: inherit !important;
  }

  .input-input-text-area {
    width: var(--width);
    max-width: var(--max-width);
    min-width: var(--min-width);

    ::v-deep .v-input__append-inner {
      .v-input__icon {
        margin-top: 1px;
        width: 16px;
        min-width: 16px;

        button {
          font-size: 18px;
        }
      }
    }
    &.always-show-scroll-y ::v-deep textarea {
      overflow-y: scroll;
    }

    &.v-text-field--outlined {
      ::v-deep textarea {
        margin-bottom: 2px;
        margin-right: 2px;
      }
    }

    &.v-text-field--filled {
      ::v-deep textarea {
        margin-top: 6px;
      }
    }
  }

  .theme--light.v-text-field.v-input--is-disabled
    ::v-deep
    .v-input__slot::before {
    border-image: none !important;
  }

  ::v-deep .theme--light.v-label--is-disabled {
    color: rgba(0, 0, 0, 0.6);
  }

  .theme--light.v-input--is-disabled ::v-deep input {
    color: rgba(0, 0, 0, 0.6);
  }

  .tablet {
    ::v-deep .v-counter {
      font-size: 24px;
      min-height: 24px;
      line-height: 24px;
    }
  }
</style>