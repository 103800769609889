<template>
  <v-btn
    @click="clickEvent"
    :max-width="maxWidth"
    :min-width="minWidth"
    :width="width"
    :disabled="disabled"
    :color="_color"
    class="print-no"
    :class="[{ 'white--text': _isWhileText }]"
    :small="small"
    :x-small="xSmall"
    :large="large"
    :x-large="xLarge"
    :icon="_icon"
  >
    <v-icon :large="xLarge">
      {{ _mdiIcon }}
    </v-icon>
  </v-btn>
</template>

<script>
  export default {
    props: {
      mdiIcon: {
        type: String,
        default: undefined,
      },
      width: {
        type: [String, Number],
        default: null,
      },
      maxWidth: {
        type: [String, Number],
        default: "30px",
      },
      minWidth: {
        type: [String, Number],
        default: "30px",
      },
      type: {
        type: String,
        default: "none",
      },
      whileText: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      xSmall: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      xLarge: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _mdiIcon: {
        get() {
          let mdiIcon = undefined;
          switch (this.type) {
            case "delete":
              mdiIcon = "mdi-trash-can-outline";
              break;
            case "detailList":
              mdiIcon = "mdi-table-of-contents";
              break;
            case "edit":
              mdiIcon = "mdi-pencil";
              break;
            case "filter":
              mdiIcon = "mdi-filter-outline";
              break;
            case "search":
              mdiIcon = "mdi-database-search";
              break;
            case "accountSearch":
              mdiIcon = "mdi-account-search";
              break;
            case "self":
              mdiIcon = "mdi-account-arrow-left";
              break;
            case "mapSearch":
              mdiIcon = "mdi-map-search";
              break;
            case "approve":
              mdiIcon = "mdi-file-check-outline";
              break;
            case "calenderMonth":
              mdiIcon = "mdi-calendar-month";
              break;
            case "calenderRange":
              mdiIcon = "mdi-calendar-range";
              break;
            case "attendance":
              mdiIcon = "mdi-file-account-outline";
              break;
            case "approveRequest":
              mdiIcon = "mdi-file-document-edit-outline";
              break;
            case "salary":
              mdiIcon = "mdi-calculator";
              break;
            case "salaryOvertime":
              mdiIcon = "mdi-clipboard-clock-outline";
              break;
            case "accountDetail":
              mdiIcon = "mdi-card-account-details";
              break;
            case "overtimeRequestList":
              mdiIcon = "mdi-file-document-edit-outline";
              break;
            case "vacationRequestList":
              mdiIcon = "mdi-clipboard-list-outline";
              break;
            case "proxyApprove":
              mdiIcon = "mdi-file-check-outline";
              break;
            case "proxyDisApprove":
              mdiIcon = "mdi-delete";
              break;
            case "proxyApproveCancel":
              mdiIcon = "mdi-file-cancel-outline";
              break;
            case "sendMail":
              mdiIcon = "mdi-email-send-outline";
              break;
            case "close":
              mdiIcon = "mdi-close";
              break;
            default:
              mdiIcon = this.mdiIcon;
              break;
          }
          return mdiIcon;
        },
      },
      _color: {
        get() {
          let color = undefined;
          switch (this.type) {
            case "delete":
              color = "orange lighten-2";
              break;
            case "detailList":
              color = "green";
              break;
            case "edit":
              color = "blue lighten-2";
              break;
            case "filter":
              color = "blue darken-2";
              break;
            case "search":
              color = "blue darken-2";
              break;
            case "accountSearch":
              color = "blue darken-2";
              break;
            case "self":
              color = "blue darken-2";
              break;
            case "mapSearch":
              color = "blue darken-2";
              break;
            case "approve":
              color = "indigo darken-1";
              break;
            case "calenderMonth":
              color = "blue darken-2";
              break;
            case "calenderRange":
              color = "blue darken-2";
              break;
            case "attendance":
              color = "blue darken-2";
              break;
            case "approveRequest":
              color = "blue darken-2";
              break;
            case "salary":
              color = "blue darken-2";
              break;
            case "salaryOvertime":
              color = "blue darken-2";
              break;
            case "accountDetail":
              color = "blue darken-2";
              break;
            case "overtimeRequestList":
              color = "blue darken-2";
              break;
            case "vacationRequestList":
              color = "blue darken-2";
              break;
            case "proxyApprove":
              color = "indigo darken-1";
              break;
            case "proxyDisApprove":
            case "proxyApproveCancel":
              color = "error";
              break;
            case "sendMail":
              color = "blue darken-2";
              break;
            case "close":
              color = "error";
              break;
            default:
              break;
          }
          return color;
        },
      },
      _isWhileText: {
        get() {
          let ret = false;
          switch (this.type) {
            case "delete":
            case "detailList":
            case "edit":
            case "filter":
            case "search":
            case "accountSearch":
            case "self":
            case "mapSearch":
            case "approve":
            case "calenderMonth":
            case "calenderRange":
            case "attendance":
            case "approveRequest":
            case "salary":
            case "salaryOvertime":
            case "accountDetail":
            case "overtimeRequestList":
            case "vacationRequestList":
            case "proxyApprove":
            case "proxyDisApprove":
            case "proxyApproveCancel":
            case "sendMail":
            case "close":
              ret = true;
              break;
            default:
              ret = this.whileText;
              break;
          }
          return ret;
        },
      },
      _icon() {
        let ret = false;
        switch (this.type) {
          case "proxyDisApprove":
          case "proxyApproveCancel":
            ret = true;
            break;
          default:
            break;
        }
        return ret;
      },
    },
    methods: {
      // ダイアログ閉じる
      clickEvent() {
        this.$emit("btnClick");
      },
    },
  };
</script>

<style scoped>
</style>
