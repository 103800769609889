<template>
  <v-expansion-panels v-model="_model" flat class="mb-2">
    <slot></slot>
  </v-expansion-panels>
</template>
<script>
  export default {
    components: {},
    data() {
      return {};
    },
    props: {
      model: {
        type: [Number, Array],
        default: null,
      },
    },
    computed: {
      _model: {
        get() {
          return this.model;
        },
        set(value) {
          this.$emit("update:model", value);
        },
      },
    },
    methods: {},
  };
</script>

<style scoped>
</style>