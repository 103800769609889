<template>
  <dialog-base
    :show="show"
    :caption="caption"
    :max-width="maxWidth"
    :min-width="minWidth"
    :width="width"
    :max-height="maxHeight"
    :min-height="minHeight"
    :height="height"
    :tablet="tablet"
  >
    <template v-slot:subTitleContents v-if="_hasSubTitleContentsSlot">
      <slot name="subTitleContents"></slot>
    </template>
    <template v-slot:contents v-if="_hasContentsSlot">
      <slot name="contents"></slot>
    </template>
    <template v-slot:actionContents>
      <btn
        type="primary"
        :btnText="okBtnText"
        :large="_isTablet"
        :tablet="_isTablet"
        @btnClick="$emit('ok-btn-click')"
      ></btn>
      <btn
        type="error"
        :btnText="cancelBtnText"
        :large="_isTablet"
        :tablet="_isTablet"
        @btnClick="$emit('cancel-btn-click')"
      ></btn>
    </template>
  </dialog-base>
</template>

<script>
  import btn from "@/components/atoms/button/btn.vue";
  import dialogBase from "@/components/atoms/dialog/dialogBase.vue";
  export default {
    components: {
      btn,
      dialogBase,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      maxWidth: {
        type: [String, Number],
        default: null,
      },
      minWidth: {
        type: [String, Number],
        default: null,
      },
      width: {
        type: [String, Number],
        default: null,
      },
      maxHeight: {
        type: [String, Number],
        default: null,
      },
      minHeight: {
        type: [String, Number],
        default: null,
      },
      height: {
        type: [String, Number],
        default: null,
      },
      caption: {
        type: String,
        default: "",
      },
      okBtnText: {
        type: String,
        default: "OK",
      },
      cancelBtnText: {
        type: String,
        default: "キャンセル",
      },
      tablet: {
        type: Boolean,
        default: false,
      },
      subTitleContents: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _hasSubTitleContentsSlot() {
        return !!this.$slots.subTitleContents || this.subTitleContents;
      },
      _hasContentsSlot() {
        return !!this.$slots.contents;
      },
      _isTablet: {
        get() {
          return this.tablet;
        },
      },
    },
    methods: {},
  };
</script>

<style scoped>
</style>