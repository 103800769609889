<template>
  <validation-provider
    :rules="_rules"
    :name="label"
    :disabled="readonly || disabled"
    v-slot="{ errors }"
    slim
  >
    <v-text-field
      v-model="_text"
      :label="label"
      :placeholder="_placeholder"
      :outlined="!readonly && !disabled"
      :filled="readonly || disabled"
      dense
      :readonly="readonly"
      :disabled="disabled"
      :hide-details="hideDetails"
      :style="styles"
      :counter="counter"
      class="input-input-text"
      :class="[
        { 'input-input-text-aligh-center': textAlign === 'center' },
        { 'input-input-text-aligh-end': textAlign === 'end' },
        { tablet: _isTablet },
      ]"
      :append-icon="appendIcon"
      :type="type"
      :clearable="clearable"
      :error-messages="errors"
      :background-color="_backgroundColor"
      ref="textField"
    >
      <template v-slot:label>
        <div class="d-flex">
          <div>{{ label }}</div>
          <v-chip
            small
            dark
            color="red darken-1"
            class="mx-2 mb-1 input-text-required-icon"
            v-if="required"
            >必須</v-chip
          >
        </div>
      </template>
    </v-text-field>
  </validation-provider>
</template>

<script>
  export default {
    props: {
      required: {
        type: Boolean,
        default: false,
      },
      fixedLabel: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: "",
      },
      placeholder: {
        type: String,
        default: "",
      },
      text: {
        type: String,
        default: "",
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      hideDetails: {
        type: [String, Boolean],
        default: "auto",
      },
      width: {
        type: String,
        default: "auto",
      },
      maxWidth: {
        type: String,
        default: "auto",
      },
      minWidth: {
        type: String,
        default: "auto",
      },
      fixedWidth: {
        type: String,
        default: null,
      },
      rules: {
        type: String,
        default: undefined,
      },
      counter: {
        type: [Boolean, Number, String],
        default: null,
      },
      appendIcon: {
        type: String,
        default: null,
      },
      type: {
        type: String,
        default: "text",
      },
      textAlign: {
        type: String,
        default: null,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      backgroundColor: {
        type: String,
        default: "white",
      },
      tablet: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _placeholder() {
        if (this.fixedLabel) {
          return " ";
        }
        return this.placeholder;
      },
      _text: {
        get() {
          return this.text;
        },
        set(value) {
          this.$emit("update:text", value);
        },
      },
      _backgroundColor: {
        get() {
          if (this.readonly || this.disabled) {
            return undefined;
          } else {
            return this.backgroundColor;
          }
        },
      },
      _rules: {
        get() {
          if (this.readonly || this.disabled) {
            return undefined;
          } else {
            return this.rules;
          }
        },
      },
      styles() {
        return {
          "--width": this.fixedWidth === null ? this.width : this.fixedWidth,
          "--max-width":
            this.fixedWidth === null ? this.maxWidth : this.fixedWidth,
          "--min-width":
            this.fixedWidth === null ? this.minWidth : this.fixedWidth,
        };
      },
      _isTablet: {
        get() {
          return this.tablet;
        },
      },
    },
    methods: {
      setFocus() {
        this.$refs.textField.focus();
      },
    },
  };
</script>

<style scoped lang="scss">
  .tablet {
    font-size: 1.9rem;
    line-height: 2rem;

    ::v-deep .v-text-field__slot label {
      font-size: 1.5rem;
    }

    ::v-deep .v-text-field__slot input {
      max-height: 3rem;
    }

    &.v-text-field--outlined {
      ::v-deep .v-text-field__slot input {
        max-height: 3rem;
        margin-top: 4px;
        padding-top: 4px;
        padding-bottom: 2px;
        margin-bottom: 2px;
      }
    }

    &.input-input-text {
      ::v-deep .v-input__append-inner {
        .v-input__icon {
          margin-top: 10px;
          width: 32px;
          min-width: 32px;

          button {
            font-size: 32px;
          }
        }
      }
    }
  }

  .input-text-required-icon {
    height: inherit !important;
  }

  .input-input-text {
    width: var(--width);
    max-width: var(--max-width);
    min-width: var(--min-width);

    ::v-deep .v-input__append-inner {
      .v-input__icon {
        margin-top: 1px;
        width: 16px;
        min-width: 16px;

        button {
          font-size: 18px;
        }
      }
    }
  }

  .input-input-text.v-text-field--filled {
    ::v-deep .v-input__control {
      .v-input__append-inner {
        margin-top: 6px;
      }
    }
  }

  .theme--light.v-text-field.v-input--is-disabled
    ::v-deep
    .v-input__slot::before {
    border-image: none !important;
  }

  ::v-deep .theme--light.v-label--is-disabled {
    color: rgba(0, 0, 0, 0.6);
  }

  .theme--light.v-input--is-disabled ::v-deep input {
    color: rgba(0, 0, 0, 0.6);
  }

  .input-input-text-aligh-center ::v-deep .v-text-field__slot input {
    text-align: center;
  }

  .input-input-text-aligh-end ::v-deep .v-text-field__slot input {
    text-align: end;
  }
</style>